<template>
    <div>
        <Card class="userinfo-card base-card font-sans">
            <template #title>
                License info
            </template>
            <template #content>
                <div class="wwd-column userinfo-wrapper" v-if="this.$store.getters.license">                   
                    <div>
                        <span class="valid-info"> Your license is valid and <b class="markup">active </b> and will <b class="markup">{{ expireIn }}</b>. </span>
                    </div>
                    <div>
                        <span class="label"> Valid from: </span>
                        <span class="detail"> 
                            {{ new Date(this.$store.getters.license.startTimestamp).toLocaleString() }} 
                        </span>
                    </div>
                    <div>
                        <span class="label"> Valid to: </span>
                        <span class="detail"> 
                            {{ new Date(this.$store.getters.license.expirationTimestamp).toLocaleString() }} 
                        </span>
                    </div>
                    <div>
                        <span class="label"> Licence type: </span>
                        <span class="detail"> 
                            {{ this.$store.getters.license.type }}
                        </span>
                    </div>
                </div>
            </template>

        </Card>    
    </div>
</template>

<script>
    import Card from 'primevue/card';
    import {  timeAgo } from '@/utils.js'
    export default {
        components: {
            Card
        },
        data() {
            return {
                user: {
                    mail: null,
                    password: '********',
                    uid: null,
                    phone_number: null,
                    email_verified: null
                },
                show_change_password_dialog: false,
                current_password: null,
                new_pasword: null,
                new_password_repeat: null,
                changing_password: false,
                error_message: null
            }
        },
        methods: {
            logOut() {
                this.$store.dispatch('logOut')
            },
            setUserData() {
                this.user.mail = this.$store.getters.user.data.email
                this.user.uid = this.$store.getters.user.data.uid
                this.user.phone_number = this.$store.getters.user.data.phone_number,
                this.user.email_verified = this.$store.getters.user.data.emailVerified
            },
            openChangePasswordDialog() {   
                this.show_change_password_dialog = true
            },
            closeChangePasswordDialog() {
                this.show_change_password_dialog = false
                this.current_password = null,
                this.new_pasword = null,
                this.new_password_repeat = null
            },
            async changePassword() {
                this.changing_password = true
                if (this.new_pasword !== this.new_password_repeat) {
                    this.error_message = 'The passwords given are different'
                    this.changing_password = false
                    return
                }
                try {
                    await this.$store.dispatch('updatePassword', {
                        current_password: this.current_password,
                        new_password: this.new_pasword
                    })
                    this.changing_password = false
                    this.closeChangePasswordDialog()
                    this.$toast.add({severity:'success', summary: 'Success!', detail: `Password changed correctly`, life: 3000}); 
                } catch(err) {
                    this.error_message = err.message
                    this.changing_password = false
                }
            }
        },
        computed: {
            expireIn() {
                if (this.$store.getters.license.type === 'no_exp') {
                    return 'never expire'
                }
                return this.$store.getters.license.expirationTimestamp ? `expire in ${timeAgo(this.$store.getters.license.expirationTimestamp)}` : 'No data'
            }
        },
        mounted() {
            this.setUserData()
        },
    }
</script>

<style scoped>
.userinfo-card {
    height: 31rem !important;
}
.userinfo-card:deep(.p-card-content) {
    min-height: 22rem;
}
.userinfo-card:deep(.p-card-title) {
    display: flex;
    align-items: center;
    font-weight: 400;
}
.userinfo-card:deep(.p-card-footer) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.userinfo-wrapper {
    display: flex;
    width: 100%;
    gap: 0.1rem;
    /* justify-content: center; */
    /* align-items: center; */
}
.userinfo-wrapper > * {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border: 1px dashed rgba(212, 212, 212, 0.411)
}
.detail {
    font-size: 0.9rem;
    color: var(--blue1);
    font-weight: 500;
    min-width: 11rem;
}
.label {
    font-size: 0.9rem;
    font-weight: 700;
    width: 5rem;
}
.valid-info {
    font-size: 0.95rem;
    font-weight: 500;
    margin-bottom: 1rem;
}
.markup {
    color: var(--green1);
}

</style>