<template>
    <div class="container">
        <UserAccount class="user-account"></UserAccount>
        <LicenseInfo class="user-account"></LicenseInfo>
    </div>
</template>
<script>

import UserAccount from '../components/settings/UserAccount.vue';
import LicenseInfo from '../components/settings/LicenseInfo.vue';
export default {
    components: {
        UserAccount,
        LicenseInfo
    }
}
</script>

<style scoped>
.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0.2rem;
}
.user-account {
    flex: 1 40%;
}

@media (max-width: 800px) {
    .container {
        flex-direction: column;
    }
    .user-account {
        flex: 1 100%;
    }
}
</style>